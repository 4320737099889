ul.tab-nav {
  list-style: none;
  border-bottom: 1px solid #bbb;
  padding-left: 5px;
}
ul.tab-nav li {
  display: inline;
}
ul.tab-nav li a.button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: -1px;
  border: 1px solid #bbb;
  color: #ddd;
}
ul.tab-nav li a.button:hover {
  box-shadow: 0 0 0;
  color: #555;
  background-color: #fcfcfc;
}
ul.tab-nav li a.active.button {
  border-bottom: 1px solid #fff;
  color: #333;
  pointer-events: none;
}
.tab-content .tab-pane {
  display: none;
}
.tab-content .tab-pane.active {
  display: block;
}
